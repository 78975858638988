import { FC } from "react";
import { useRouter } from "next/router";
import { BlogCard, ResourceItem } from "@/components/Resources";
import Typography from "@/components/Typography";
import { ChevronIcon, ArrowIcon } from "@/components/Icon";
import { ClickableCard } from "@/components/Card";
import Button from "@/components/Button";
import Grid from "@/components/Grid";
import {
  useResourcesQuery,
  useBlogsQuery,
  RESOURCE_CATEGORIES,
  Blog,
} from "@/fetch/gworld";
import { Skeleton } from "@/components/Loader";
import { useResponsive, useTrackers } from "@/hooks";
import styles from "./SuperliteDashboard.module.scss";
import { InstagramPostsCard } from "../Social";

const SuperliteDashboard: FC = () => {
  const { push } = useRouter();
  const { track } = useTrackers();

  // remove before going live
  const tempBlogs = [
    {
      title: "A day of travelling will bring a basketful of learning. Abeny B.",
      thumbnail:
        "https://blog.globalworkandtravel.com/content/images/size/w1200/2022/07/holly-mandarich-UVyOfX3v0Ls-unsplash.jpg",
      marketing: {
        url: "https://blog.globalworkandtravel.com/a-day-of-travelling-will-bring-a-basketful-of-learning/",
      },
    },
    // {
    //   title: "Top 5 photography tips for beginners!",
    //   thumbnail:
    //     "https://blog.globalworkandtravel.com/content/images/size/w1200/2022/02/5.Havefunb.jpg",
    //   marketing: {
    //     url: "https://blog.globalworkandtravel.com/top-5-photography-tips-for-beginners/",
    //   },
    // },
    {
      title:
        "Why now is actually the PERFECT time to plan your Working Holiday!",
      thumbnail:
        "https://dvna932ue33yp.cloudfront.net/blog-migration/hubfs/474541/gap-year-travels-3.jpg",
      marketing: {
        url: "https://blog.globalworkandtravel.com/why-now-is-actually-the-perfect-time-to-start/",
      },
    },
  ] as Array<Blog>;

  const { data: visaResourcesData, isLoading: isLoadingVisaResources } =
    useResourcesQuery(RESOURCE_CATEGORIES.Visa);
  const { data: guidesResourcesData, isLoading: isLoadingGuidesResources } =
    useResourcesQuery(RESOURCE_CATEGORIES.Guides);
  const {
    data: postArrivalResourcesData,
    isLoading: isLoadingPostArrivalResources,
  } = useResourcesQuery(RESOURCE_CATEGORIES.PostArrivalDocuments);

  const { isMobile } = useResponsive();

  const extraResources = [
    ...(visaResourcesData?.result || []).map((each, idx) => ({
      resource: each,
      type: RESOURCE_CATEGORIES.Visa,
      index: idx,
    })),
    ...(guidesResourcesData?.result || []).map((each, idx) => ({
      resource: each,
      type: RESOURCE_CATEGORIES.Guides,
      index: idx,
    })),
    ...(postArrivalResourcesData?.result || []).map((each, idx) => ({
      resource: each,
      type: RESOURCE_CATEGORIES.PostArrivalDocuments,
      index: idx,
    })),
  ];

  const isLoadingExtraResources =
    isLoadingGuidesResources ||
    isLoadingPostArrivalResources ||
    isLoadingVisaResources;

  const goToVisaPage = () => {
    push("/experience/visas");
    track("SuperLite Dashboard Card Clicked", {
      eventId: "superlite-dashboard-card-clicked",
      type: "visa",
    });
  };

  const goToResourcesPage = () => {
    push("/experience/resources?tab=resources");
    track("SuperLite Dashboard All Resources Clicked", {
      eventId: "superlite-dashboard-all-resources-clicked",
    });
  };

  const goToResumePage = () => {
    push("/experience/resume");
    track("SuperLite Dashboard Card Clicked", {
      eventId: "superlite-dashboard-card-clicked",
      type: "resume",
    });
  };

  return (
    <div>
      <Typography color="text.secondary" variant="h6" className={styles.title}>
        Explore travel visas
      </Typography>
      <ClickableCard className={styles.card} onClick={goToVisaPage}>
        <div className={styles.cardDetails}>
          <Typography variant="h6">Check your visa eligibility.</Typography>
          <Typography variant="body2" className={styles.cardDescription}>
            Plan your trip with confidence using our comprehensive guide, filled
            with essential travel information and visa options.
          </Typography>
          <Typography variant="subtitle1" className={styles.actionText}>
            See options
            <ArrowIcon dir="right" className={styles.arrowIcon} />
          </Typography>
        </div>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={`/images/dashboard-visa-card.svg`}
          alt="icon"
          className={styles.cardIcon}
        />
      </ClickableCard>

      {isLoadingExtraResources ? (
        <Skeleton variant="text" className={styles.resourceTitleContainer} />
      ) : extraResources.length > 0 ? (
        <div className={styles.resourceTitleContainer}>
          <Typography
            variant="h6"
            color="text.secondary"
            className={styles.resourceTitle}
          >
            {isMobile ? (
              <>
                Ready yourself <br />
                for your journey ahead
              </>
            ) : (
              "Ready yourself for your journey ahead"
            )}
          </Typography>
          <Button
            endIcon={<ChevronIcon dir="right" />}
            onClick={goToResourcesPage}
            variant="text"
            color="info"
            className={styles.viewButton}
          >
            View all
          </Button>
        </div>
      ) : undefined}
      <Grid container spacing="16px">
        {isLoadingExtraResources
          ? [1, 2, 3, 4].map((each) => (
              <Grid item md={6} xs={12} key={each}>
                <Skeleton variant="rectangular" width={305} height={104} />
              </Grid>
            ))
          : extraResources.slice(0, 4).map((each) => (
              <Grid item md={6} xs={12} key={each.resource.title}>
                <ResourceItem
                  resource={each.resource}
                  type={each.type}
                  index={each.index}
                />
              </Grid>
            ))}
      </Grid>

      <Typography color="text.secondary" variant="h6" className={styles.title}>
        Craft your career
      </Typography>

      <ClickableCard className={styles.card} onClick={goToResumePage}>
        <div className={styles.cardDetails}>
          <Typography variant="h6">
            Give your resume an AI-powered edge.
          </Typography>
          <Typography variant="body2" className={styles.cardDescription}>
            Create a standout resume and land your dream job.
          </Typography>
          <Typography variant="subtitle1" className={styles.actionText}>
            Start now
            <ArrowIcon dir="right" className={styles.arrowIcon} />
          </Typography>
        </div>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={`/images/dashboard-resume-card.svg`}
          alt="icon"
          className={styles.cardIcon}
        />
      </ClickableCard>
      <InstagramPostsCard />
      <Typography variant="h6" color="text.secondary" className={styles.title}>
        Explore essential travel insights and tips
      </Typography>
      <div className={styles.blogContainer}>
        {tempBlogs.map((each) => (
          <BlogCard key={each.id} blog={each} className={styles.blogCard} />
        ))}
      </div>
    </div>
  );
};

export default SuperliteDashboard;
